import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';

import {
  LocaleType,
  NEXTJS_LOCALES_TO_LANG,
  NEXTJS_DEFAULT_LOCALE,
} from '@constants/internationalConstants';
import { AppEnvStates } from '../../src/constants';

type Props = {
  title: string;
  metaDescription: string;
  ogImageUrl?: string;
  ogImageWidth?: number;
  ogImageHeight?: number;
  noFollow: boolean;
  relativeCanonicalURL?: string;
  customHeaderElements?: ReactNode;
  customProperties?: Record<string, unknown>;
  /**
   * This is used to add structured data to the page.
   * Structured data is used by Google to display rich results in search results.
   * @see https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data
   */
  structuredData?: string;
};

const assetPrefix = process.env.assetPrefix || '';

const Meta = ({
  title,
  metaDescription,
  ogImageUrl,
  ogImageWidth,
  ogImageHeight,
  noFollow,
  relativeCanonicalURL,
  customHeaderElements,
  customProperties = {},
  structuredData,
}: Props): JSX.Element => {
  const { asPath } = useRouter();

  const isBasePath = relativeCanonicalURL
    ? relativeCanonicalURL === '/'
    : asPath === '/';

  const path = isBasePath ? '' : relativeCanonicalURL || asPath;

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content={ogImageUrl || `${assetPrefix}/img/og-image.png`}
        />
        <meta property="og:type" content="website" />
        {/*
          Facebook recommends setting og:image:width and og:image:height for faster image previews, see:
          https://developers.facebook.com/docs/sharing/best-practices#precaching
        */}
        {ogImageWidth !== undefined && (
          <meta property="og:image:width" content={ogImageWidth.toString()} />
        )}
        {ogImageHeight !== undefined && (
          <meta property="og:image:height" content={ogImageHeight.toString()} />
        )}
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="robots"
          content={noFollow ? 'noindex, nofollow' : 'index, follow'}
        />
        <meta
          name="google-site-verification"
          content="ecGmKrz2L0OF4hJv-EZzzcJrdesLJKjeb_OAJ2pnIv0"
        />
        <link rel="canonical" href={`https://www.secondnature.io${path}`} />
        {/*
          This is so Google knows the localized versions of our pages
          https://developers.google.com/search/docs/advanced/crawling/localized-versions?visit_id=637462128356401666-1183033794&rd=1#language-codes
        */}
        {(Object.keys(NEXTJS_LOCALES_TO_LANG) as LocaleType[]).map(key => (
          <link
            key={key}
            rel="alternate"
            hrefLang={`${NEXTJS_LOCALES_TO_LANG[key]}`}
            href={`https://www.secondnature.io${
              key === NEXTJS_DEFAULT_LOCALE ? '' : `/${key}`
            }${path}`}
          />
        ))}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://www.secondnature.io${path}`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-57x57.png`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-114x114.png`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-72x72.png`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-144x144.png`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="60x60"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-60x60.png`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-120x120.png`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="76x76"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-76x76.png`}
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href={`${assetPrefix}/img/favicons/apple-touch-icon-152x152.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${assetPrefix}/img/favicons/favicon-196x196.png`}
          sizes="196x196"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${assetPrefix}/img/favicons/favicon-96x96.png`}
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${assetPrefix}/img/favicons/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${assetPrefix}/img/favicons/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${assetPrefix}/img/favicons/favicon-128x128.png`}
          sizes="128x128"
        />
        <meta name="application-name" content="Second Nature" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta
          name="msapplication-TileImage"
          content={`${assetPrefix}/img/favicons/mstile-144x144.png`}
        />
        <meta
          name="msapplication-square70x70logo"
          content={`${assetPrefix}/img/favicons/mstile-70x70.png`}
        />
        <meta
          name="msapplication-square150x150logo"
          content={`${assetPrefix}/img/favicons/mstile-150x150.png`}
        />
        <meta
          name="msapplication-wide310x150logo"
          content={`${assetPrefix}/img/favicons/mstile-310x150.png`}
        />
        <meta
          name="msapplication-square310x310logo"
          content={`${assetPrefix}/img/favicons/mstile-310x310.png`}
        />
        {/*
          GA4 uses Google Tag Manager to load Google Analytics, we can reduce the page
          speed impact by adding preconnect links, which tells the browser
          "We will definitely connect to this server, so open up a connection"
        */}
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        {customHeaderElements}
        {structuredData && (
          <script
            dangerouslySetInnerHTML={{ __html: structuredData }}
            key="structured-data"
            type="application/ld+json"
          />
        )}
      </Head>
      {/* Mixpanel */}
      {process.env.mixpanelToken && (
        <Script
          id="load-mixpanel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
              0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
              for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
              MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
              mixpanel.init('${process.env.mixpanelToken}');`,
          }}
        />
      )}
      {/* Start Google Tag Manager */}
      <Script
        id="load-google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID}');`,
        }}
      />
      {/* End Google Tag Manager */}
      {process.env.APP_ENV === AppEnvStates.PRODUCTION && (
        <>
          {/* Intercom (tweaked to load on scroll for significant boost to PageSpeed / Lighthouse score) */}
          <Script
            id="load-intercom"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
                window.intercomSettings = {
                  app_id: "xijefjvx",
                  hide_default_launcher: ${customProperties.hideIntercom}
                };
                var intercomLoader = function(){
                  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/xijefjvx';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
                  window.removeEventListener('scroll', intercomLoader);
                }
                window.addEventListener('scroll', intercomLoader);`,
            }}
          />
        </>
      )}
    </>
  );
};

Meta.defaultProps = {
  title: 'The Second Nature Weight Loss Programme | Used by the NHS',
  metaDescription:
    'Second Nature is an online weight loss plan that helps you to make long-term healthy lifestyle changes. We are used and trusted by the NHS.',
  noFollow: false,
};

export default Meta;
